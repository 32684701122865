.safety-banner-div1{
    min-height: 75vh;
  background: linear-gradient(180deg,#151515 58%,#fafafa 0);
  padding-bottom: 60px;
}

.safety-banner-div1 .container-fluid{
  padding-top: 174px !important;
  background: url(../Images/image\ 1896.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.safety-banner-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.safety-banner-text-div1 div{
    width: 50%;
}

.safety-banner-text-div2 h1{
color: #FFF;
font-family: "Bebas Neue";
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: 90px; /* 86.592% */
text-transform: uppercase;
margin-top: 32px;
}

.safety-banner-text-div2 p{
    color: #FFF;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 57px; /* 61.44px */
text-transform: capitalize;
}

.safety-banner-text-div2 p span{
color: #F6901F;
}


.safety-banner-text-div3 p{
color: #D7D7D7;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 168.75% */
text-transform: capitalize;
margin-bottom: 0px;
}


.safety-banner-pic1 img{
    margin-top: 70px;
}

.safety-para1-div1 p{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 168.75% */
text-transform: capitalize;
    margin-top: 40px;
}




























@media screen and (max-width: 576px) {

    .safety-banner-div1{
    min-height: 75vh;
  background: linear-gradient(180deg,#151515 61%,#fafafa 0);
  padding-bottom: 60px;
}

.safety-banner-div1 .container-fluid{
  padding-top: 77px !important;
}

.safety-banner-text-div1{
    display: block;
    align-items: center;
    justify-content: space-between;
}

.safety-banner-text-div1 div{
    width: 100%;
}

.safety-banner-text-div2 h1{
font-size: 54px;
line-height: 56px; /* 86.592% */
margin-top: 0px;
}

.safety-banner-text-div2 p{
font-size: 30px;
line-height: 40px; /* 61.44px */
}

.safety-banner-text-div3 p{
font-size: 16px;
line-height: 27px; /* 168.75% */
text-transform: capitalize;
margin-bottom: 0px;
margin-top: 24px;
}


.safety-banner-pic1 img{
    margin-top: 40px;
}

.safety-para1-div1 p{
font-size: 16px;
line-height: 26px; /* 168.75% */
text-transform: capitalize;
    margin-top: 40px;
}


}