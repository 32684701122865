.home-banner-div1 {
  padding-bottom: 110px;
  padding-top: 185px;
  background: url(../Images/image\ 1896.svg);
  background-repeat: no-repeat;
  background-size: cover;

  background-color: #151515;
}

.home-banner-text-div1 p {
  color: #ffa94a;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38.892px; /* 121.536% */
}

.home-banner-text-div1 h1 {
  color: #fff;
  font-family: "Bebas Neue";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 86px; /* 94.08px */
  text-transform: uppercase;
  /* display: inline-flex;
align-items: center; */
}

.home-banner-text-div1 h1 .clients-text1 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff3e5;
  padding: 16px 26px;
  column-gap: 12px;
  height: 100%;
  transform: translateY(-17px);
}

.home-banner-text-div1 h1 div div h4 {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 236.544% */
  margin-bottom: 0px;
}

.home-banner-text-div1 h1 div div p {
  color: #02293d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 376.632% */
  text-transform: capitalize;
  margin-bottom: 0px;
}

.home-banner-text-div1 div a div {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 275.085% */
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  margin-bottom: 0px;
  margin-top: 22px;
  width: 360px;
  background-color: #f6901f;
}

.home-banner-div22 {
  background: #fff;
  padding: 35px 0px;
}

.home-banner2-text-div22 p {
  color: #383838;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px;
}

.home-banner2-text-div22 p span {
  color: #db7e16;
}

.home-banner-img-div22 img {
  position: absolute;
  top: -600px;
  padding-left: 30px;
  max-width: 98% !important;
}





















/* // ###################### section no ### 2 ############# */

.home-sec2-div1{
    padding-top: 50px;
    padding-bottom: 80px;
    background: #FAFAFA;
}

.home-sec2-text-div1 p{
color: #383838;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 145.455% */
}

.home-sec2-text-div1 p span{
font-weight: 700;
}

.home-sec2-text-div1 p:last-child{
margin-top: 30px;
}

.home-sec2-img-div{
    display: flex;
    margin-top: 40px;
}

.home-sec2-img1-div1{
width: 35%;
padding-right: 10px;
}

.home-sec2-img1-div1 img{
    width: 100%;
height: 100%;
}

.home-sec2-img2-maindiv1{
    width: 65%;
    display: flex;
    flex-wrap: wrap;
}

.home-sec2-img3-div1{
    width: 60%;
    height: 56%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.home-sec2-img3-div1 div{
    width: 100%;
height: 100%;
background: url(../Images/image\ 1931.svg);
background-repeat: no-repeat;
background-size: cover;
background-color: #000;
display: grid;
place-items: center;
place-content: center;
}

.home-sec2-img3-div1 div h1{
font-family: Poppins;
font-size: 124px;
font-style: normal;
font-weight: 700;
line-height: 100px; /* 37.903% */
background: linear-gradient(116deg, #F6901F -4.71%, #FFBA6E 91.98%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.home-sec2-img3-div1 p{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 131.818% */
margin-bottom: 0px;
margin-top: 5px;
}

.home-sec2-img4-div1{
    width: 40%;
    height: 56%;
    padding-left: 10px;
    padding-bottom: 10px;
}

.home-sec2-img4-div1 img{
    width: 100%;
height: 100%;
}

.home-sec2-img5-div1{
    width: 60%;
    height: 44%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.home-sec2-img5-div1 img{
    width: 100%;
height: 100%;
}

.home-sec2-img6-div1{
    width: 40%;
    height: 44%;
    padding-left: 10px;
    padding-top: 10px;
}

.home-sec2-img6-div1 img{
    width: 100%;
height: 100%;
}


























/* // ###################### section no ### 33 ############# */


.home-service-div1{
    padding-top: 80px;
    padding-bottom: 90px;
}

.home-service-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-service-text-div2 p{
color: #F6901F;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 346.369% */
text-transform: uppercase;
margin-bottom: 0px;
}

.home-service-text-div2 h2{
color: #000;
font-family: Poppins;
font-size: 74px;
font-style: normal;
font-weight: 700;
line-height: 84px; /* 112.336% */
text-transform: uppercase;
}

.home-service-text-div3 a div{
width: 250px;
flex-shrink: 0;
padding: 19px 24px;
color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 275.085% */
text-transform: capitalize;
display: flex;
align-items: center;
justify-content: space-between;
background-color: #F6901F;
margin-bottom: 0px;
}


.home-service-row-div11{
margin-top: 90px;
row-gap: 86px;
}

.home-service-card-div1{
display: flex;
align-items: flex-end;
column-gap: 16px;
}

.home-service-card-textdiv1 h4{
color: #212121;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 27.896px */
}

.home-service-card-textdiv1 div a div{
color: #000;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 168.75% */
text-decoration-line: underline;
margin-top: 18px;
}

.home-service-card-img22 img{
margin-top: -30px;
}


.home-construction-icons{
  display: none;
}

.home-service-card-img22{
  display: none;
}

















/* // ###################### section no ### 44 ############# */


.home-project-div1{
    padding-top: 110px;
    padding-bottom: 70px;
    background: url(../Images/image\ 1933.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #151515;
}

.home-project-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-project-text-div2 p{
color: #F6901F;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 346.369% */
text-transform: uppercase;
margin-bottom: 0px;
}

.home-project-text-div2 h2{
color: #FFF;
font-family: Poppins;
font-size: 74px;
font-style: normal;
font-weight: 700;
line-height: 86px; /* 112.336% */
text-transform: uppercase;
}

.home-project-text-div3 a div{
width: 370px;
flex-shrink: 0;
padding: 19px 24px;
color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 275.085% */
text-transform: capitalize;
display: flex;
align-items: center;
justify-content: space-between;
background-color: #F6901F;
margin-bottom: 0px;
}



.home-project-list-div1{
margin-top: 120px;
}

.home-project-line-div1{
width: 100%;
background: #454545;
height: 1px;
}

.home-project-list-div22{
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-project-list-text-div1{
width: 14%;
}

.home-project-list-text-div1 h3{
color: #C2C2C2;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 80% */
margin-bottom: 0px !important;
}

.home-project-list-text-div1 h3 span{
color: #FBB042;
}

.home-project-list-text-div2{
width: 38%;
}

.home-project-list-text-div2 h2{
color: #ABA5A5;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 100% */
text-transform: uppercase;
margin-bottom: 0px !important;
}

.home-project-list-text-div3{
width: 18%;

}

.home-project-list-text-div3 p{
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 735.714% */
text-transform: capitalize;
margin-bottom: 0px !important;
}

.home-project-list-text-div4{
width: 30%;
}

.home-project-list-text-div4 img{

}


























.home-client-div11{
  padding-top: 100px;
  padding-bottom: 80px;
  background: #FAFAFA;
}

.home-client-text11 p{
color: #F6901F;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 346.369% */
text-transform: uppercase;
margin-bottom: 0px;
margin-left: 5px;
}

.home-client-text11 h2{
color: #000;
font-family: Poppins;
font-size: 74px;
font-style: normal;
font-weight: 700;
line-height: 82px; /* 112.336% */
text-transform: uppercase;
}

.home-clients-logo-row-div{
row-gap: 14px;
}

.home-clients-logo-row-div div.col-lg-3{
  padding-left: 7px;
  padding-right: 7px;
}

.home-client-logo-div11{
background: #FFF;
box-shadow: 0px 4px 14px 0px rgba(195, 195, 195, 0.25);
width: 100%;
height: 74px;
display: flex;
align-items: center;
justify-content: center;
padding: 15px;
}






















.home-inquiry-div1{
padding-top: 80px;
  background: linear-gradient(180deg,#FAFAFA 55%, #151515 0);
}

.home-inquiry-div1122{
  background: url(../Images/image\ 1933\ \(1\).svg);
background-position-y: 200px;
}

.home-inquiry-div1 div.container{
background: #F6901F;
padding: 80px 85px;
padding-right: 70px;

}


.home-inquiry-text-div1 h2{
color: #FFF;
font-family: Poppins;
font-size: 54.724px;
font-style: normal;
font-weight: 700;
line-height: 58.008px; /* 106% */
}

.home-inquiry-btn1 a div{
color: #FAFAFA;
text-align: center;
font-family: Poppins;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 275.085% */
text-transform: capitalize;
background-color: #212121;
width: 320px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 25px;
margin-top: 30px;
}

.inquery-img-div11{
  position: absolute;
top: -146px;
    left: -35px;
}

.inquery-img-div11 img{
max-width: 100% !important;
}

































@media screen and (max-width: 576px) {

    .home-banner-div1 {
  padding-bottom: 40px;
  padding-top: 77px;
  overflow: hidden;
}

.home-banner-text-div1 p {
  font-size: 24px;
  line-height: 34px; /* 121.536% */
}

.home-banner-text-div1 h1 {
  font-size: 46px;
  line-height: 63px; /* 94.08px */
  /* display: inline-flex;
align-items: center; */
}

.home-banner-text-div1 h1 .clients-text1 {
  padding: 8px 14px;
  column-gap: 9px;
  transform: translateY(-2px);
}

.home-banner-text-div1 h1 div div h4 {
  font-size: 16px;
  line-height: 20px; /* 236.544% */
  margin-bottom: 0px;
}

.home-banner-text-div1 h1 div div p {
  font-size: 10px;
  line-height: 14px; /* 376.632% */
  margin-bottom: 0px;
}

.home-banner-text-div1 div a div {
  font-size: 20px;
  line-height: 27px; /* 275.085% */
  padding: 18px 30px;
  margin-bottom: 0px;
  margin-top: 26px;
  width: 100%;
  margin-bottom: 65px;
}





.home-banner-div22 {
  background: #fff;
  padding: 45px 0px;
}

.home-banner2-text-div22 p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0px;
  margin-top: 30px;
  padding: 0px 13px;
}

.home-banner2-text-div22 p span {
  color: #db7e16;
}

.banner-pattern-img11{
  width: 260px;
}

.home-banner-img-div22 img {
  position: absolute;
  top: -600px;
  height: 410px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 94% !important;
}
















/* // ###################### section no ### 2 ############# */

.home-sec2-div1{
    padding-top: 50px;
    padding-bottom: 60px;
}

.home-sec2-text-div1 p{
font-size: 16px;
line-height: 24px; /* 145.455% */
}

.home-sec2-text-div1 p:last-child{
margin-top: 25px;
}

.home-sec2-img-div{
    display: block;
    margin-top: 40px;
    height: 100%;
}

.home-sec2-img1-div1{
width: 100%;
height: 100%;
padding-right: 0px;
padding-bottom: 7px;
}

.home-sec2-img2-maindiv1{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.home-sec2-img3-div1{
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.home-sec2-img3-div1 div{
padding: 25px;
}

.home-sec2-img3-div1 div h1{
font-size: 64px;
line-height: 70px; /* 37.903% */
}

.home-sec2-img3-div1 p{
font-size: 16px;
line-height: 24px; /* 131.818% */
margin-top: 0px;
}

.home-sec2-img4-div1{
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.home-sec2-img5-div1{
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.home-sec2-img6-div1{
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-top: 7px;
}


















/* // ###################### section no ### 33 ############# */


.home-service-div1{
    padding-top: 60px;
    padding-bottom: 70px;
}


.home-service-text-div2 p{
font-size: 24px;
line-height: 32px; /* 346.369% */
}

.home-service-text-div2 h2{
font-size: 48px;
line-height: 56px; /* 112.336% */
}

.home-service-row-div11{
margin-top: 40px;
row-gap: 60px;
}

.home-service-card-div1{
display: flex;
align-items: flex-end;
column-gap: 16px;
}

.home-service-card-textdiv1 h4{
font-size: 22px;
line-height: 28px; /* 27.896px */
margin-top: 20px;
}

.home-service-card-textdiv1 div a div{
font-size: 15px;
line-height: 24px; /* 168.75% */
margin-top: 10px;
}

.home-construction-icons{
  width: 140px;
  display: none;
}

.home-construction-icons img{
  width: 80%;
}

.home-service-card-img22{
  width: 140px;
}

.home-service-card-img22 img{
margin-top: -10px;
width: 80%;
}

















/* // ###################### section no ### 44 ############# */


.home-project-div1{
    padding-top: 70px;
    padding-bottom: 70px;
}

.home-project-text-div2 p{
font-size: 24px;
line-height: 34px; /* 346.369% */
}

.home-project-text-div2 h2{
font-size: 48px;
line-height: 60px; /* 112.336% */
}

.home-project-list-div1{
margin-top: 40px;
}

.home-project-line-div1{
width: 100%;
background: #454545;
height: 1px;
}

.home-project-list-div22{
    display: block;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.home-project-list-text-div1{
width: 100%;
}

.home-project-list-text-div1 h3{
font-size: 36px;
line-height: 44px; /* 80% */
}

.home-project-list-text-div1 h3 span{
color: #FBB042;
}

.home-project-list-text-div2{
width: 100%;
}

.home-project-list-text-div2 h2{
font-size: 30px;
line-height: 38px; /* 100% */
margin-top: 12px;
}

.home-project-list-text-div3{
width: 100%;
}

.home-project-list-text-div3 p{
font-size: 15px;
line-height: 22px; /* 735.714% */
margin-bottom: 0px !important;
margin-top: 16px;
}

.home-project-list-text-div4{
width: 100%;
margin-top: 20px;
}

.home-project-list-text-div4 img{
width: 100% !important; 
height: 100%;
}





















.home-client-div11{
  padding-top: 70px;
  padding-bottom: 70px;
}

.home-client-text11 p{
font-size: 20px;
line-height: 28px; /* 346.369% */
margin-left: 0px;
text-align: center;
}

.home-client-text11 h2{
font-size: 52px;
line-height: 60px; /* 112.336% */
text-transform: uppercase;
text-align: center;
}

.home-clients-logo-row-div{
row-gap: 14px;
margin-top: 28px;
}

.home-clients-logo-row-div div.col-lg-3{
  padding-left: 7px;
  padding-right: 7px;
}

.home-client-logo-div11{
height: 75px;
padding: 18px;
}




















.home-inquiry-div1{
padding-top: 70px;
  background: linear-gradient(180deg,#FAFAFA 55%, #151515 0);
}

.home-inquiry-div1 div.container{
background: #F6901F;
padding: 40px 20px;
padding-top: 0px;
padding-right: 20px;

}


.home-inquiry-text-div1 h2{
font-size: 38px;
line-height: 44px; /* 106% */
margin-top: 35px;
text-align: center;
}

.home-inquiry-btn1{
  display: flex;
  justify-content: center;
}

.home-inquiry-btn1 a div{
font-size: 18px;
line-height: 26px; /* 275.085% */
width: 260px;
padding: 16px 25px;
margin-top: 15px;
}

.inquery-img-div11{
  position: relative;
        top: 00px;
        left: -20px;
        margin-top: -80px;
}

.inquery-img-div11 img{
max-width: 110% !important;
}






}











@media screen and (max-width: 425px) {
.home-banner-img-div22 img {
  position: absolute;
  top: -610px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 96% !important;
}

.home-banner2-text-div22 p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0px;
  margin-top: 25px;
  padding: 0px 13px;
}


}




@media screen and (max-width: 370px) {


.home-service-card-img22 img{
margin-top: -10px;
width: 90%;
}
}