@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

a {
  text-decoration: none !important;
}

.navbar-div1 {
  position: absolute;
  width: 100% !important;
  z-index: 9;
  background: transparent;
  padding: 20px 110px;
  padding-top: 30px !important;
}


@media screen and (max-width: 1280px) {
.navbar-div1 {
  padding: 20px 50px;
}
}

.navbar-ul-div1 {
  display: flex !important;
  justify-content: center;
}

.navbar-ul1 {
  column-gap: 28px;
  display: flex;
  align-items: center;
}

.navbar-ul1 .nav-item {
  display: flex;
  align-items: center;
}

.navbar-ul1 .nav-item .nav-link {
color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 277.798% */
}

.navbar-ul1 .nav-item .nav-link:hover {
  color: #F6901F !important;
}


.navbar-brand h1{
    color: #fff;
  font-family: "Bebas Neue";
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 94.08px */
  text-transform: uppercase;
  margin-bottom: 0px;
  letter-spacing: 1px;
}


.navbar-brand h1 span{
    color: #F6901F;
}


  .navbar-contact-btn1 {
 color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 20px;
  margin-bottom: 0px !important;
  width: 200px;
  height: 52px;
  flex-shrink: 0;
  background: #F6901F;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 11px;
}





.mobile-navbar-div1 {
  background: #151515 !important;
  min-height: 80px;
  width: 100%;
  z-index: 9999;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
  padding-bottom: 20px;
}

@media screen and (max-width: 991px) {


  .mobile-navbar-div1 {
    display: block;
    width: 100%;
    top: 0px;
    left: 50%;
    z-index: 9999;
    transform: translatex(-50%);
  }
}

.mobile-nav-toogler {
  border: none;
  /* background: linear-gradient(95deg, #F7B53C 0.98%, #F7B53C 42.72%, #FFCD73 90.99%); */
  padding: 0px;
}

.mobile-nav-item .nav-link {
  color: #151515 !important;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 191.812% */
letter-spacing: -0.18px;
  margin-left: 0px !important;
}


.mobile-nav-item.home1 .mobilenav-lines-svg {
  min-width: 100%;
  height: 0.5px;
  background: #000 !important;
  margin-top: 8px;
  margin-bottom: 10px;
}

.mobile-nav-item .nav-link:hover {
  color: #151515 !important;
}

.mobile-btn-lin1{
background: white;
height: 2px;
width: 26px;
}

.mobile-btn-lin2{
background: white;
height: 2px;
margin-top: 5px;
width: 26px;
}

.mobile-btn-lin3{
background: white;
height: 2px;
}

.mobile-navbar-div2 {
  /* background: #EAEFFF; */
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-right: 30px;
  z-index: 3000 !important;
  padding-left: 30px;
  display: none !important;
  animation: fade 0.5s ease-in-out;
  box-shadow: 1px 1px 10px rgb(24, 24, 24);
}

.mobile-navbar-div2.show {
  /* background: #EAEFFF; */
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 90px;
  transition: none;
  z-index: 9999;
  /* transform: translatey(0%) !important; */
  /* transition: all 0.5s ease-in-out; */
  display: block !important;
  animation: fade 0.5s ease-in-out;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 1px 1px 10px rgb(24, 24, 24);

}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile-nav-item.active .nav-link {
  color: black !important;
}

@media screen and (max-width: 576px) {
  .nav-text1,
  .dark-nav-text1 {
    font-size: 16px;
  }

  .navbar-brand h1{
  font-size: 46px;
  line-height: 30px; /* 94.08px */
  letter-spacing: 1px;
  margin-top: 5px;
}
}

.mobile-call-btn1 {
  background: #FFBC49;
  padding: 12px;
}



.mobile-qoute-btn122 {
  background:  #ffa339;
  width: 100%;
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-top: 10px;
  margin-bottom: 0px !important;
  column-gap: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-qoute-btn122 svg{
  margin-bottom: 0px;
}


.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}



.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff !important;
}




