.services-banner-div1{
    min-height: 75vh;
  background: linear-gradient(180deg,#151515 72%,#fafafa 0);
  
}

.services-banner-div1 .container-fluid{
  padding-top: 176px !important;
  background: url(../Images/image\ 1896.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.services-banner-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.services-banner-text-div1 div{
    width: 50%;
}

.services-banner-text-div2 h1{
color: #FFF;
font-family: "Bebas Neue";
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: 90px; /* 86.592% */
text-transform: uppercase;
margin-top: 32px;
}


.services-banner-text-div3 p{
color: #D7D7D7;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 168.75% */
text-transform: capitalize;
margin-bottom: 0px;
}


.services-banner-pic1 img{
    margin-top: 80px;
}
























.services-scope-div1{
padding-top: 60px;
padding-bottom: 80px;
background: #FAFAFA;
}

.services-scope-row-div1{

}

.services-scope-row-div1 .col-lg-8{
padding-right: 30px !important;
}


.services-scope-row-div1 .col-lg-4{
padding-left: 30px !important;
}


.services-scope-text-div1{

}

.services-scope-text-div1 h1{
color: #231F20;
font-family: Poppins;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 43.86px */
margin-top: 20px;
}

.services-scope-text-div1 p{
color: #494949;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 156.25% */
}


.services-scope-img-div1{
margin-top: 25px;
margin-bottom: 25px;
}
































@media screen and (max-width: 576px) {
    .services-banner-div1{
    min-height: 60vh;
  background: linear-gradient(180deg,#151515 90%,#fafafa 0);

 }

.services-banner-div1 .container-fluid{
  padding-top: 77px !important;
}

.services-banner-text-div1{
    display: block;
    align-items: center;
    justify-content: space-between;
}
.services-banner-text-div1 div{
    width: 100%;
}

.services-banner-text-div2 h1{
font-size: 56px;
line-height: 56px; /* 86.592% */
margin-top: 30px;
}

.services-banner-text-div3 p{
font-size: 16px;
line-height: 27px; /* 168.75% */
margin-bottom: 0px;
}

.services-banner-pic1 img{
    margin-top: 36px;
}














.services-scope-div1{
padding-top: 40px;
padding-bottom: 70px;
background: #FAFAFA;
}

.services-scope-row-div1{

}

.services-scope-row-div1 .col-lg-8{
padding-right: 15px !important;
}

.services-scope-row-div1 .col-lg-4{
padding-left: 15px !important;
}

.services-scope-text-div1{

}

.services-scope-text-div1 h1{
font-size: 28px;
line-height: 34px; /* 43.86px */
margin-top: 24px;
}

.services-scope-text-div1 p{
font-size: 16px;
line-height: 25px; /* 156.25% */
}


.services-scope-img-div1{
margin-top: 25px;
margin-bottom: 25px;
}


}


