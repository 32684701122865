.about-banner-div1{
    min-height: 75vh;
  /* background-color: #151515; */
  background: linear-gradient(180deg,#151515 68%,#ffff 0);
  
}

.about-banner-div1 .container-fluid{
  padding-top: 188px !important;
  background: url(../Images/image\ 1896.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.about-banner-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-banner-text-div2 h1{
color: #FFF;
font-family: "Bebas Neue";
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: 80px; /* 86.592% */
text-transform: uppercase;
}

.about-banner-text-div2 p{
color: #FFF;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 48px; /* 173.184% */
margin-bottom: 0px;
}

.about-banner-text-div2 p span{
color: #FFB15B;
}


.about-banner-text-div3{
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.about-banner-text-div3 div h2{
color: #FFF;
text-align: right;
font-family: Poppins;
font-size: 96px;
font-style: normal;
font-weight: 700;
line-height: 84px; /* 128.64px */
margin-bottom: 0px;
}

.about-banner-text-div3 div h4{
color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 32.16px */
margin-bottom: 0px;
}


.about-banner-pic1 img{
    margin-top: 120px;
}




















/* // ###################### section no 22 ### 3 #############} */



.about-demolition-div1{
padding-top: 90px;
padding-bottom: 85px;
}

.about-demolition-text-div1 p{
color: #494949;
font-family: Inter;
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 88.462% */
}

.about-demolition-text-div1 h2{
color: #000;
font-family: "Bebas Neue";
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: 98%; /* 94.08px */
text-transform: uppercase;
}

.about-demolition-text-div2 p{
color: #494949;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
}























/* {// ###################### section no 33 ### 3 #############} */

.about-last-sec-div1{
background: #151515;
padding-top: 90px;
padding-bottom: 85px;
position: relative;
overflow: hidden;
}

.about-vector-svg{
position: absolute;
right: 0px;
top: 35%;
opacity: 0.26;
}

.about-vector-svg2{
position: absolute;
right: -100px;
top: -6%;
opacity: 0.12;
}

.about-last-sec-img-div1{
max-height: 64px;
}

.about-last-sec-div2 div h3{
    color: #F6901F;
font-family: "Bebas Neue";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: 40px; /* 67.344% */
margin-top: 42px;
margin-bottom: 24px;
}

.about-last-sec-div2 div p{
color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 150% */
padding-right: 30px;
}



























@media screen and (max-width: 576px) {



    .about-banner-div1{
    min-height: 52vh;
  /* background-color: #151515; */
  background: linear-gradient(180deg,#151515 85%,#ffff 0);
  
}

.about-banner-div1 .container-fluid{
  padding-top: 77px !important;
}

.about-banner-text-div1{
    display: block;
    align-items: center;
    justify-content: space-between;
}

.about-banner-text-div2 h1{
font-size: 52px;
line-height: 52px; /* 86.592% */
}

.about-banner-text-div2 p{
font-size: 30px;
line-height: 36px; /* 173.184% */
}


.about-banner-text-div3{
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-top: 30px;
}

.about-banner-text-div3 div h2{
font-size: 64px;
line-height: 68px; /* 128.64px */
margin-bottom: 0px;
}

.about-banner-text-div3 div h4{
font-size: 20px;
line-height: 28px; /* 32.16px */
margin-bottom: 0px;
}


.about-banner-pic1 img{
    margin-top: 40px;
}




















/* // ###################### section no 22 ### 3 #############} */



.about-demolition-div1{
padding-top: 75px;
padding-bottom: 66px;
}

.about-demolition-text-div1 p{
font-size: 20px;
line-height: 26px; /* 88.462% */
}

.about-demolition-text-div1 h2{
font-size: 48px;
line-height: 55px; /* 94.08px */
}

.about-demolition-text-div2 p{
font-size: 16px;
line-height: 22px; /* 137.5% */
}




















/* {// ###################### section no 33 ### 3 #############} */

.about-last-sec-div1{
padding-top: 72px;
padding-bottom: 70px;
}

.about-vector-svg{
position: absolute;
right: 0px;
top: 35%;
opacity: 0.26;
}

.about-vector-svg2{
position: absolute;
right: -100px;
top: 16%;
opacity: 0.12;
}

.about-last-sec-div1 .container .row{
    row-gap: 40px;
}

.about-last-sec-img-div1{
max-height: 64px;
}

.about-last-sec-div2 div h3{
font-size: 52px;
line-height: 54px; /* 67.344% */
margin-top: 22px;
margin-bottom: 6px;
}

.about-last-sec-div2 div p{
font-size: 16px;
line-height: 24px; /* 150% */
padding-right: 30px;
}


}