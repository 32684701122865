.footer-div1{
  padding-bottom: 70px;
  padding-top: 100px;
  background: url(../Images/image\ 1933\ \(1\).svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #151515;
}

.footer-text-div2{

}

.footer-text-div2 h4{
color: #C9C9C9;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 150% */
text-transform: capitalize;
}

.footer-text-div2 p{
color: #D1D1D1;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 168.75% */
margin-top: 22px;
}


.footer-logo1{
    margin-top: 20px;
}

.footer-text-div2 h3{
color: #C9C9C9;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 27px; /* 150% */
text-transform: capitalize;
margin-top: 35px;
}

.social-footer-icons-div1{
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.footer-logo-div1{
    color: #fff;
  font-family: "Bebas Neue";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 94.08px */
  text-transform: uppercase;
  margin-bottom: 0px;
  letter-spacing: 1px;
}


.footer-logo-div1 span{
    color: #F6901F;
}




























@media screen and (max-width: 576px) {
    .footer-div1{
  padding-bottom: 60px;
  padding-top: 70px;
}

.footer-text-div2{
margin-top: 26px;
}

.footer-text-div2 h4{
font-size: 22px;
line-height: 26px; /* 150% */
}

.footer-text-div2 p{
font-size: 16px;
line-height: 26px; /* 168.75% */
margin-top: 14px;
}


.footer-logo1{
    margin-top: 20px;
}

.footer-text-div2 h3{
font-size: 20px;
line-height: 28px; /* 150% */
margin-top: 35px;
}

.social-footer-icons-div1{
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-top: 14px;
}


}