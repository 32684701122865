
.project-main-div11{
  background-color: #FAFAFA;
}

.project-banner-div1{
    min-height: 80vh;
    /* padding-bottom: 110px; */
  background: linear-gradient(180deg,#151515 88%,#fafafa 0);
}

.project-banner-div1 .container-fluid{
  padding-top: 180px !important;
  background-image: url(../Images/image\ 1896.svg);
  background-repeat: no-repeat;
  background-size: cover;
}


.project-banner-text-div1 h1{
color: #FFF;
font-family: "Bebas Neue";
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: 80px; /* 40.512% */
}



.project-banner-pic1 img{
    margin-top: 54px;
}






















.project-scope-div1{
padding-top: 60px;
padding-bottom: 80px;
background: #FAFAFA;
}

.project-scope-row-div1{

}

.project-scope-row-div1 .col-lg-8{
padding-right: 30px !important;
}


.project-scope-row-div1 .col-lg-4{
padding-left: 30px !important;
}


.project-scope-text-div1{

}

.project-scope-text-div1 h1{
color: #000;
font-family: Poppins;
font-size: 44px;
font-style: normal;
font-weight: 700;
line-height: 64px; /* 145.455% */
margin-top: 20px;
}

.project-scope-text-div1 h1 span{
color: #F6901F;
}

.project-scope-text-div1 p{
color: #494949;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 156.25% */
}

.project-scope-text-div1 ul{
padding-left: 20px;
}

.project-scope-text-div1 ul li{
color: #494949;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

.project-scope-img-div1{
display: flex;
flex-wrap: wrap;
margin-top: 35px;
margin-bottom: 25px;
}

.project-scope-img-div2{
width: 30%;
padding-right: 8px;
}

.project-scope-img-div2 img:first-child{
width: 100%;
height: 50%;
padding-bottom: 8px;
}

.project-scope-img-div2 img:last-child{
width: 100%;
height: 50%;
padding-top: 8px;
}

.project-scope-img-div3{
  width: 70%;
  padding-left: 8px;
}

.project-scope-img-div3 img{
width: 100%;
height: 100%;
}













.project-sidebar-div1{
padding: 22px 16px;
padding-bottom: 16px;
background-color: #FFF;
margin-top: 28px;
}

.project-sidebar-btn-div1 a div{
background: #F6901F;
width: 100%;
padding: 18px 22px;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 20.64px */
margin-bottom: 0px;
}

.project-sidebar-btn-div2 a div{
  background: #F4F4F4;
width: 100%;
padding: 18px 22px;
color: #000000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 20.64px */
margin-bottom: 0px;
margin-top: 15px;
}




.project-download-btn-div1 a div{
  border-radius: 53px;
border: 1px solid #BFBFBF;
width: 100%;
padding: 20px 24px;
color: #000 ;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 20.64px */
margin-bottom: 0px;
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 45px;
}







.projrct-sidebar-build-div1{
  width: 100%;
  height: 485px;
  background: #F7901F;
  padding-top: 60px;
  text-align: center;
  margin-top: 45px;
  position: relative;
}

.projrct-sidebar-build-div1 .project-sidebarimg-div11{
  width: 100%;
  position: absolute;
  bottom: 0px;
left: 50%;
transform: translateX(-50%);
}


.project-sidebar-build-btndiv1 a div{
border-radius: 33px;
border: 1px solid #FFF;
padding: 11px 22px;
display: inline-flex;
align-items: center;
column-gap: 12px;
color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 330.102% */
margin-bottom: 0px;
position: relative;
z-index: 9;
}

.projrct-sidebar-build-div1 h2{
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 25px;
position: relative;
z-index: 9;
}


.project-sidebar-build-btndiv2 a div{
border-radius: 22px;
background: #FFF;
display: inline-flex;
padding: 12px 22px;
justify-content: center;
align-items: center;
gap: 16px;
flex-shrink: 0;
color: #F7901F;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 441.197% */
margin-bottom: 0px;
margin-top: 27px;
position: relative;
z-index: 9;
}



























@media screen and (max-width: 576px) {

.project-banner-div1{
    min-height: 20vh;
    /* padding-bottom: 110px; */
  background: linear-gradient(180deg,#151515 80%,#fafafa 0);
}

.project-banner-div1 .container-fluid{
  padding-top: 77px !important;
}

.project-banner-text-div1 h1{
font-size: 58px;
line-height: 58px; /* 40.512% */
}

.project-banner-pic1 img{
    margin-top: 30px;
}













.project-scope-div1{
padding-top: 40px;
padding-bottom: 70px;
background: #FAFAFA;
}

.project-scope-row-div1 .col-lg-8{
padding-right: 15px !important;
}

.project-scope-row-div1 .col-lg-4{
padding-left: 15px !important;
}

.project-scope-text-div1 h1{
font-size: 30px;
line-height: 36px; /* 145.455% */
margin-top: 24px;
}

.project-scope-text-div1 p{
font-size: 16px;
line-height: 25px; /* 156.25% */
}

.project-scope-text-div1 ul{
padding-left: 20px;
}

.project-scope-text-div1 ul li{
font-size: 16px;
line-height: 24px; /* 150% */
}

.project-scope-img-div1{
display: flex;
flex-wrap: wrap;
margin-top: 35px;
margin-bottom: 27px;
}

.project-scope-img-div2{
width: 30%;
padding-right: 5px;
}

.project-scope-img-div2 img:first-child{
width: 100%;
height: 50%;
padding-bottom: 5px;
}

.project-scope-img-div2 img:last-child{
width: 100%;
height: 50%;
padding-top: 5px;
}

.project-scope-img-div3{
  width: 70%;
  padding-left: 5px;
}













.project-sidebar-div1{
padding: 20px 15px;
padding-bottom: 16px;
background-color: #FFF;
margin-top: 34px;
}

}





@media screen and (max-width: 374px) {
  .project-banner-text-div1 h1{
font-size: 54px;
line-height: 58px; /* 40.512% */
}

}