.contact-banner-div1{
    min-height: 90vh;
  background: linear-gradient(180deg,#151515 67%,#fafafa 0);
}

.contact-banner-div1 .container-fluid{
  padding-top: 174px !important;
  background: url(../Images/image\ 1896.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-banner-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-banner-text-div1 div{
    width: 50%;
}

.contact-banner-text-div2 h1{
color: #FFF;
font-family: "Bebas Neue";
font-size: 80px;
font-style: normal;
font-weight: 400;
line-height: 90px; /* 86.592% */
text-transform: uppercase;
margin-top: 32px;
}

.contact-banner-text-div2 p{
    color: #FFF;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 57px; /* 61.44px */
text-transform: capitalize;
}

.contact-banner-text-div2 p span{
color: #F6901F;
}


.contact-banner-text-div3 p{
color: #D7D7D7;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 168.75% */
text-transform: capitalize;
margin-bottom: 0px;
}

.contact-banner-form-div1{
    padding: 50px;
    background: #FFF;
    margin-top: 70px;
}


.input-field12{
    border: 1px solid #B6B6B6 !important;
    color: #7D7D7D;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 20.043px; /* 111.352% */
height: calc(1.5em + 1.5rem + 2px) !important;
padding: .5rem 1.2rem;
border-radius: 3px;
}

.text-area12{
border: 1px solid #B6B6B6 !important;
color: #7D7D7D;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 111.352% */
padding: 1rem 1.2rem;
border-radius: 3px;
margin-top: 15px;
}


.form-btn12{
color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 275.085% */
text-transform: capitalize;
padding: 17px 40px;
width: 100%;
background-color: #F6901F;
border: none !important;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 15px;
}



























.contact-location-div1{
    padding-top: 50px; 
    padding-bottom: 65px;
    background-color: #fafafa;
}

.contact-location-text-div1 h1{
color: #000;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 38px; /* 90% */
}

.contact-location-text-div2{
display: flex;
align-items: center;
column-gap: 18px;
}

.contact-location-text-div2 div p{
color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 193.75% */
text-transform: capitalize;
margin-bottom: 0px;
}



























@media screen and (max-width: 576px) {

    .contact-banner-div1{
    min-height: 90vh;
  background: linear-gradient(180deg,#151515 67%,#fafafa 0);
}

.contact-banner-div1 .container-fluid{
  padding-top: 77px !important;
}

.contact-banner-text-div1{
    display: block;
    align-items: center;
    justify-content: space-between;
}

.contact-banner-text-div1 div{
    width: 100%;
}

.contact-banner-text-div2 h1{
font-size: 48px;
line-height: 54px; /* 86.592% */
text-transform: uppercase;
margin-top: 0px;
}

.contact-banner-text-div2 p{
font-size: 30px;
line-height: 40px; /* 61.44px */
}


.contact-banner-text-div3 p{
font-size: 16px;
line-height: 27px; /* 168.75% */
text-transform: capitalize;
margin-bottom: 0px;
}

.contact-banner-form-div1{
    padding: 25px 20px;
    background: #FFF;
    margin-top: 40px;
}

.form-row{
    row-gap: 15px;
}


.input-field12{
font-family: Poppins;
font-size: 16px;
}

.text-area12{
font-size: 16px;
}


.form-btn12{
font-size: 18px;
line-height: 26px; /* 275.085% */
padding: 16px 20px;
margin-top: 15px;
}















.contact-location-div1{
    padding-top: 46px; 
    padding-bottom: 60px;
    background-color: #fafafa;
}

.contact-img-for-mobile{
    margin-bottom: 15px;
}

.contact-location-text-div1 h1{
font-size: 24px;
line-height: 38px; /* 90% */
margin-top: 10px;
}

.contact-location-text-div2{
display: flex;
align-items: start;
column-gap: 18px;
}

.contact-location-text-div2 div p{
font-size: 16px;
line-height: 24px; /* 193.75% */
text-transform: capitalize;
margin-bottom: 0px;
}

}









@media screen and (max-width: 364px) {

    .contact-banner-text-div2 h1{
font-size: 44px;
line-height: 52px; /* 86.592% */
text-transform: uppercase;
margin-top: 0px;
}
.contact-location-text-div2 div p{
font-size: 14px;
line-height: 24px; /* 193.75% */
text-transform: capitalize;
margin-bottom: 0px;
}
}
